<template>
    <div class="">
        <div class="relative" >
            <span class="bg-white absolute top-2 left-4" v-if="field">{{placeholder}}</span>
            <input
            :value="field" 
            v-on:input="updateValue($event.target.value)"
            :type="`${status ? 'text' : 'password'}`"
            style="border-color:none !important;box-shadow:none !important"
            :class="`block border border-gray-200 rounded-xl items-center w-full p-2 px-4  text-xs ${field ? ' pt-8' :' pt-4 pb-4'}`"
            :name="field"
            :placeholder="placeholder" />
            <div class="absolute h-full top-0 right-0 flex items-center text-sm pr-4">
                <i class="fa fa-eye cursor-pointer" v-if="status" @click="show()"></i>
                <i class="fa fa-eye-slash  cursor-pointer" v-else @click="show()"></i>

            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props:['field','placeholder','type'],
    data:()=>({
        status:false,
        value:''
    }),
    methods: {
        show() {
            this.status = !this.status
        },
        updateValue: function (field) {
            this.value = field
            this.$emit('input', field);
        }
        
    }
}
</script>